import { FunctionComponent, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import CSS, { Property } from "csstype";

type ButtonType = {
  buttonHref: string;
  buttonText?: string;

  /** Style props */
  buttonPadding?: Property.Padding;
  buttonTextFontSize?: Property.FontSize;
  buttonBackgroundColor?: Property.BackgroundColor;
  buttonBorder?: Property.Border;
};

const Button: FunctionComponent<ButtonType> = memo(
  ({
    buttonHref,
    buttonPadding,
    buttonText,
    buttonTextFontSize,
    buttonBackgroundColor,
    buttonBorder,
  }) => {
    const buttonStyle: CSS.Properties = useMemo(() => {
      return {
        padding: buttonPadding,
        backgroundColor: buttonBackgroundColor,
        border: buttonBorder,
      };
    }, [buttonPadding, buttonBackgroundColor, buttonBorder]);

    const buttonTextStyle: CSS.Properties = useMemo(() => {
      return {
        fontSize: buttonTextFontSize,
      };
    }, [buttonTextFontSize]);

    return (
      <Link
        className="cursor-pointer [text-decoration:none] rounded-8xs bg-steelblue flex flex-row p-3 items-center justify-center text-left text-base text-white font-body-xs"
        to={buttonHref}
        style={buttonStyle}
      >
        <div className="flex flex-row py-0 px-4 items-center justify-center">
          <div
            className="relative tracking-[0.5px] leading-[24px] font-medium"
            style={buttonTextStyle}
          >
            {buttonText}
          </div>
        </div>
      </Link>
    );
  }
);

export default Button;
