import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import GoodBox from "../components/GoodBox";
import Button from "../components/Button";

const Goods: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-29xl text-white font-montserrat">
      <header className="self-stretch flex flex-row py-6 px-20 items-center justify-between">
        <img
          className="relative w-[220px] h-[67.76px] object-cover"
          alt=""
          src="/logo@2x.png"
        />
        <nav className="m-0 flex flex-row items-start justify-start gap-[16px] text-left text-base text-blue-3 font-body-xs">
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <a
              className="[text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              href="/"
            >
              Главная
            </a>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/about"
            >
              О нас
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/services"
            >
              Услуги
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/goods"
            >
              Товары
            </Link>
          </div>
          <div className="rounded-8xs flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-3">
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <Link
                className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
                to="/contact"
              >
                Контакты
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div className="self-stretch bg-blue-3 flex flex-row p-12 items-start justify-center">
        <div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
          <b className="relative">Наши товары</b>
          <div className="relative text-5xl leading-[150%] text-gray-6 inline-block w-[1824px]">
            Вы также можете обратиться к нашему опытному менеджеру, чтобы
            уточнить все детали относительно наших товаров. Наша команда готова
            ответить на любые вопросы, предоставить дополнительную информацию и
            помочь вам с выбором товаров, соответствующих вашим требованиям и
            потребностям. Наши менеджеры обладают глубокими знаниями о нашем
            ассортименте и всегда готовы обеспечить вам профессиональную
            поддержку. Не стесняйтесь связаться с нами через наши контактные
            данные, указанные на сайте, и мы с удовольствием поможем вам с
            любыми вопросами, возникающими при выборе и заказе товаров “ГЛОБАЛ
            ЛОГИСТИК”.
          </div>
          <div className="self-stretch flex flex-row items-center justify-center gap-[24px] sm:flex-col">
            <GoodBox title="Текстиль для дома" />
            <GoodBox image={`url("/content-box6@3x.png")`} title="Одежда" />
            <GoodBox image={`url("/content-box7@3x.png")`} title="Обувь " />
            <GoodBox
              image={`url("/content-box8@3x.png")`}
              title="Текстиль для отеля"
            />
            <GoodBox image={`url("/content-box9@3x.png")`} title="Косметика" />
          </div>
          <div className="self-stretch flex flex-row pt-0 px-0 pb-12 items-center justify-center gap-[24px] sm:flex-col">
            <GoodBox image={`url("/content-box10@3x.png")`} title="Фрукты" />
            <GoodBox
              image={`url("/content-box11@3x.png")`}
              title="Молочные продукты"
            />
            <GoodBox
              image={`url("/content-box12@3x.png")`}
              title="Готовая еда"
            />
            <GoodBox
              image={`url("/content-box13@3x.png")`}
              title="Чистящие средства"
            />
            <GoodBox image={`url("/content-box14@3x.png")`} title="Мебель" />
          </div>
          <Button
            buttonHref="/contact"
            buttonPadding="16px"
            buttonText="Связаться с нами"
            buttonTextFontSize="20px"
            buttonBackgroundColor="#0096ce"
            buttonBorder="unset"
          />
        </div>
      </div>
      <footer className="self-stretch bg-white flex flex-col py-0 px-20 items-start justify-center text-left text-base text-blue-gray-600 font-body-xs">
        <div className="self-stretch flex flex-row py-12 px-0 items-start justify-start gap-[48px] border-b-[1px] border-solid border-blue-gray-200">
          <div className="flex-1 flex flex-col items-start justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative w-[220px] h-[67.76px] bg-[url(/public/logo1@3x.png)] bg-cover bg-no-repeat bg-[top]"
              to="/"
            />
          </div>
          <nav className="m-0 flex-1 flex flex-col items-start justify-start text-left text-base text-blue-gray-600 font-body-xs">
            <div className="self-stretch flex flex-row py-3 px-0 items-center justify-start gap-[8px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--codepen2.svg"
              />
              <div className="relative leading-[110%] font-medium">
                Компания
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/about"
              >
                О нас
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/goods"
              >
                Товары
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Услуги
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Контакты
              </Link>
            </div>
          </nav>
        </div>
        <div className="self-stretch flex flex-row pt-12 px-0 pb-6 items-center justify-start">
          <div className="flex-1 relative leading-[140%]">
            @ 2023 globallogisticspro.ru. Все права защищены
          </div>
        </div>
        <div className="self-stretch flex flex-row pt-6 px-0 pb-12 items-start justify-start text-xs">
          <div className="flex-1 relative leading-[140%]">
            {" "}
            "ГЛОБАЛ ЛОГИСТИК" предлагает широкий спектр услуг в области
            сухопутного пассажирского транспорта, строительства и технического
            обслуживания автотранспортных средств. Компания сочетает опыт,
            надежность, высокое качество услуг и индивидуальный подход, чтобы
            удовлетворить потребности своих клиентов и оставаться лидером в
            своей отрасли.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Goods;
