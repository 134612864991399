import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type AnotherBoxType = {
  picture?: string;
  category?: string;
  text?: string;
  showPictureIcon?: boolean;

  /** Style props */
  fontWeight?: Property.FontWeight;
};

const AnotherBox: FunctionComponent<AnotherBoxType> = memo(
  ({ picture, category, fontWeight, text, showPictureIcon }) => {
    const categoryStyle: CSS.Properties = useMemo(() => {
      return {
        fontWeight: fontWeight,
      };
    }, [fontWeight]);

    return (
      <div className="flex-1 rounded-3xs bg-white shadow-[0px_4px_6px_-1px_rgba(0,_0,_0,_0.1),_0px_0px_4px_rgba(0,_0,_0,_0.07)] overflow-hidden shrink-0 flex flex-col items-start justify-center text-left text-base text-blue-gray-900 font-body-xs border-[1px] border-solid border-blue-gray-200">
        <img
          className="self-stretch max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src={picture}
        />
        <div className="self-stretch flex flex-col p-4 items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="flex-1 flex flex-col items-start justify-start">
              <div
                className="self-stretch relative leading-[140%] font-medium"
                style={categoryStyle}
              >
                {category}
              </div>
              <div className="self-stretch relative text-sm leading-[140%] text-blue-gray-600">
                {text}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AnotherBox;
