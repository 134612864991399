import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import GoodBox from "../components/GoodBox";
import AnotherBox from "../components/AnotherBox";
import Feature from "../components/Feature";

const Home: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-21xl text-blue-gray-900 font-body-xs">
      <header className="self-stretch flex flex-row py-6 px-20 items-center justify-between">
        <img
          className="relative w-[220px] h-[67.76px] object-cover"
          alt=""
          src="/logo@2x.png"
        />
        <nav className="m-0 flex flex-row items-start justify-start gap-[16px] text-left text-base text-blue-3 font-body-xs">
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <a
              className="[text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              href="/"
            >
              Главная
            </a>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/about"
            >
              О нас
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/services"
            >
              Услуги
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/goods"
            >
              Товары
            </Link>
          </div>
          <div className="rounded-8xs flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-3">
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <Link
                className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
                to="/contact"
              >
                Контакты
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div className="self-stretch bg-white flex flex-row pt-32 pb-12 pr-0 pl-20 items-center justify-start gap-[80px] text-53xl text-deepskyblue">
        <div className="self-stretch w-[671px] flex flex-col items-start justify-center gap-[24px]">
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-start justify-start">
              <h1 className="m-0 self-stretch relative text-[inherit] leading-[110%] font-extrabold font-inherit">
                ПЕРЕМЕЩЕНИЕ, СТРОИТЕЛЬСТВО И ОБСЛУЖИВАНИЕ
              </h1>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start">
            <div className="w-[640px] flex flex-row items-start justify-start">
              <Button buttonHref="/about" buttonText="О нас" />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col py-0 px-20 items-end justify-center">
          <img
            className="relative w-[610px] h-[333px] object-cover"
            alt=""
            src="/truck@2x.png"
          />
        </div>
      </div>
      <div className="self-stretch bg-white flex flex-col p-20 items-center justify-center gap-[24px]">
        <div className="self-stretch flex flex-row items-center justify-start gap-[24px]">
          <div className="flex-1 flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-start justify-start">
              <h2 className="m-0 self-stretch relative text-[inherit] leading-[110%] font-bold font-inherit">
                Наши услуги
              </h2>
            </div>
          </div>
          <div className="rounded-lg flex flex-row py-3 px-0 items-center justify-center text-base text-blue-600">
            <Link
              className="cursor-pointer [text-decoration:none] flex flex-row py-0 px-4 items-center justify-center text-[inherit]"
              to="/services"
            >
              <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                Подррбнее
              </div>
            </Link>
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/icon--jamicons--outline--logos--arrowright.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[24px]">
          <GoodBox
            image={`url("/content-box@3x.png")`}
            title="Дальние перевозки"
          />
          <GoodBox
            image={`url("/content-box1@3x.png")`}
            title="Аренда и лизинг транспорта"
          />
          <GoodBox
            image={`url("/content-box2@3x.png")`}
            title="Разработка проекта домов"
          />
          <GoodBox
            image={`url("/content-box3@3x.png")`}
            title="Строительство домов"
          />
          <GoodBox
            image={`url("/content-box4@3x.png")`}
            title="Реконструкция и отделка"
          />
        </div>
      </div>
      <div className="self-stretch bg-blue-gray-100 overflow-hidden flex flex-col py-20 px-0 items-center justify-center gap-[24px]">
        <div className="self-stretch flex flex-row py-0 px-20 items-center justify-start gap-[24px]">
          <div className="flex-1 flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-start justify-start">
              <h2 className="m-0 self-stretch relative text-[inherit] leading-[110%] font-bold font-inherit">
                Наши товары
              </h2>
            </div>
          </div>
          <div className="rounded-lg flex flex-row py-3 px-0 items-center justify-center text-base text-blue-600">
            <Link
              className="cursor-pointer [text-decoration:none] flex flex-row py-0 px-4 items-center justify-center text-[inherit]"
              to="/goods"
            >
              <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                Подробнее
              </div>
            </Link>
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/icon--jamicons--outline--logos--arrowright.svg"
            />
          </div>
        </div>
        <div className="self-stretch overflow-x-auto flex flex-row py-0 px-20 items-start justify-start gap-[24px] text-base">
          <AnotherBox
            picture="/picture@2x.png"
            category="Запчасти"
            text="Двигатели, трансмиссии, тормозные системы и т.д."
            showPictureIcon
          />
          <div className="flex-1 rounded-3xs bg-white shadow-[0px_4px_6px_-1px_rgba(0,_0,_0,_0.1),_0px_0px_4px_rgba(0,_0,_0,_0.07)] overflow-hidden shrink-0 flex flex-col items-start justify-center relative border-[1px] border-solid border-blue-gray-200">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
              alt=""
              src="/picture1@2x.png"
            />
            <div className="self-stretch flex flex-col p-4 items-start justify-start z-[1]">
              <div className="self-stretch flex flex-row items-center justify-start">
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[140%]">
                    Пищевые продукты
                  </div>
                  <div className="self-stretch relative text-sm leading-[140%] text-blue-gray-600">
                    Фрукты, овощи, мясо, молочные продукты и т.д.
                  </div>
                </div>
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-skyblue h-6 flex flex-row py-2 px-1 box-border items-center justify-center z-[2] text-sm text-steelblue">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%] font-semibold">
                  Лучшее
                </div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel.svg"
              />
            </div>
          </div>
          <AnotherBox
            picture="/picture2@2x.png"
            category="Напитки"
            fontWeight="unset"
            text="Алкогольные напитки, воду, соки, чай и кофе."
            showPictureIcon
          />
          <div className="flex-1 rounded-3xs bg-white shadow-[0px_4px_6px_-1px_rgba(0,_0,_0,_0.1),_0px_0px_4px_rgba(0,_0,_0,_0.07)] overflow-hidden shrink-0 flex flex-col items-start justify-center border-[1px] border-solid border-blue-gray-200">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/picture3@2x.png"
            />
            <div className="self-stretch flex flex-col p-4 items-start justify-start">
              <div className="self-stretch flex flex-row items-center justify-start">
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[140%]">
                    Табачные изделия
                  </div>
                  <div className="self-stretch relative text-sm leading-[140%] text-blue-gray-600">
                    Сигареты, сигары, трубки и табак для курения.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-white flex flex-col p-20 items-center justify-center gap-[80px] text-center">
        <div className="w-[1280px] flex flex-col items-center justify-start">
          <div className="self-stretch flex flex-col items-center justify-start">
            <h2 className="m-0 self-stretch relative text-[inherit] leading-[110%] font-bold font-inherit">
              Мы лучшие - и вот почему
            </h2>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[48px]">
          <div className="flex-1 bg-white overflow-hidden flex flex-col items-center justify-start">
            <Feature
              iconIconoirTrophy="/icon--iconoir--trophy.svg"
              title="Опыт и надежность"
            />
          </div>
          <div className="flex-1 bg-white overflow-hidden flex flex-col items-start justify-start">
            <Feature
              iconIconoirTrophy="/iconoir3dselectface.svg"
              iconIconoirTrophyOverflow="unset"
              iconIconoirTrophyFlexShrink="unset"
              title="Широкий спектр услуг"
            />
          </div>
          <div className="flex-1 bg-white overflow-hidden flex flex-col items-center justify-start">
            <Feature
              iconIconoirTrophy="/iconoirclipboardcheck.svg"
              iconIconoirTrophyOverflow="unset"
              iconIconoirTrophyFlexShrink="unset"
              title="Квалифицированный персонал"
            />
          </div>
          <div className="flex-1 bg-white overflow-hidden flex flex-col items-center justify-start">
            <Feature
              iconIconoirTrophy="/icon--iconoir--systemrestart.svg"
              iconIconoirTrophyOverflow="hidden"
              iconIconoirTrophyFlexShrink="0"
              title="Индивидуальный подход"
            />
          </div>
          <div className="flex-1 bg-white overflow-hidden flex flex-col items-center justify-start">
            <Feature
              iconIconoirTrophy="/iconoircartalt.svg"
              iconIconoirTrophyOverflow="unset"
              iconIconoirTrophyFlexShrink="unset"
              title="Низкие цены"
            />
          </div>
        </div>
        <Button
          buttonHref="/services"
          buttonPadding="16px"
          buttonText="Наши услуги"
          buttonTextFontSize="20px"
          buttonBackgroundColor="#0096ce"
          buttonBorder="unset"
        />
      </div>
      <div className="self-stretch bg-blue-gray-900 flex flex-row pt-20 px-20 pb-0 items-center justify-start gap-[80px] text-37xl text-white">
        <div className="self-stretch flex-1 flex flex-col items-start justify-center">
          <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[110%] font-extrabold">
                Хотите знать про нас больше? Напишите нам.
              </div>
            </div>
            <div className="flex flex-row items-center justify-start">
              <Button
                buttonHref="/contact"
                buttonPadding="16px"
                buttonText="Контакты"
                buttonTextFontSize="20px"
                buttonBackgroundColor="unset"
                buttonBorder="2px solid #fff"
              />
            </div>
          </div>
        </div>
        <div className="w-[530px] flex flex-col items-start justify-center">
          <img
            className="relative w-[550px] h-[480px] object-cover"
            alt=""
            src="/truck2@2x.png"
          />
        </div>
      </div>
      <footer className="self-stretch bg-white flex flex-col py-0 px-20 items-start justify-center text-left text-base text-blue-gray-600 font-body-xs">
        <div className="self-stretch flex flex-row py-12 px-0 items-start justify-start gap-[48px] border-b-[1px] border-solid border-blue-gray-200">
          <div className="flex-1 flex flex-col items-start justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative w-[220px] h-[67.76px] bg-[url(/public/logo1@3x.png)] bg-cover bg-no-repeat bg-[top]"
              to="/"
            />
          </div>
          <nav className="m-0 flex-1 flex flex-col items-start justify-start text-left text-base text-blue-gray-600 font-body-xs">
            <div className="self-stretch flex flex-row py-3 px-0 items-center justify-start gap-[8px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--codepen.svg"
              />
              <div className="relative leading-[110%] font-medium">
                Компания
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/about"
              >
                О нас
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/goods"
              >
                Товары
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Услуги
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Контакты
              </Link>
            </div>
          </nav>
        </div>
        <div className="self-stretch flex flex-row pt-12 px-0 pb-6 items-center justify-start">
          <div className="flex-1 relative leading-[140%]">
            @ 2023 globallogisticspro.ru. Все права защищены
          </div>
        </div>
        <div className="self-stretch flex flex-row pt-6 px-0 pb-12 items-start justify-start text-xs">
          <div className="flex-1 relative leading-[140%]">
            {" "}
            "ГЛОБАЛ ЛОГИСТИК" предлагает широкий спектр услуг в области
            сухопутного пассажирского транспорта, строительства и технического
            обслуживания автотранспортных средств. Компания сочетает опыт,
            надежность, высокое качество услуг и индивидуальный подход, чтобы
            удовлетворить потребности своих клиентов и оставаться лидером в
            своей отрасли.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
