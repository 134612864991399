import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type FeatureType = {
  iconIconoirTrophy?: string;
  title?: string;

  /** Style props */
  iconIconoirTrophyOverflow?: Property.Overflow;
  iconIconoirTrophyFlexShrink?: Property.FlexShrink;
};

const Feature: FunctionComponent<FeatureType> = memo(
  ({
    iconIconoirTrophy,
    iconIconoirTrophyOverflow,
    iconIconoirTrophyFlexShrink,
    title,
  }) => {
    const iconIconoirTrophyStyle: CSS.Properties = useMemo(() => {
      return {
        overflow: iconIconoirTrophyOverflow,
        flexShrink: iconIconoirTrophyFlexShrink,
      };
    }, [iconIconoirTrophyOverflow, iconIconoirTrophyFlexShrink]);

    return (
      <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-xl text-blue-gray-900 font-body-xs">
        <div className="flex flex-row items-center justify-center">
          <img
            className="relative w-16 h-16 overflow-hidden shrink-0"
            alt=""
            src={iconIconoirTrophy}
            style={iconIconoirTrophyStyle}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="flex-1 relative leading-[110%] font-medium">
              {title}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Feature;
