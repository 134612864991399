import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type GoodBoxType = {
  title?: string;

  /** Style props */
  image?: Property.BackgroundImage;
};

const GoodBox: FunctionComponent<GoodBoxType> = memo(({ image, title }) => {
  const contentBoxStyle: CSS.Properties = useMemo(() => {
    return {
      backgroundImage: image,
    };
  }, [image]);

  return (
    <div
      className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start bg-[url(/public/content-box5@3x.png)] bg-cover bg-no-repeat bg-[top] text-left text-5xl text-white font-body-xs sm:flex-[unset] sm:self-stretch"
      style={contentBoxStyle}
    >
      <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start">
        <div className="self-stretch flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-start">
            <b className="flex-1 relative leading-[110%]">{title}</b>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GoodBox;
