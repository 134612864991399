import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const About: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-xl text-gray-6 font-montserrat">
      <header className="self-stretch flex flex-row py-6 px-20 items-center justify-between">
        <img
          className="relative w-[220px] h-[67.76px] object-cover"
          alt=""
          src="/logo@2x.png"
        />
        <nav className="m-0 flex flex-row items-start justify-start gap-[16px] text-left text-base text-blue-3 font-body-xs">
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <a
              className="[text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              href="/"
            >
              Главная
            </a>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/about"
            >
              О нас
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/services"
            >
              Услуги
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/goods"
            >
              Товары
            </Link>
          </div>
          <div className="rounded-8xs flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-3">
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <Link
                className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
                to="/contact"
              >
                Контакты
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div className="self-stretch bg-blue-3 flex flex-row p-12 items-start justify-center gap-[24px]">
        <div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
          <b className="relative text-29xl text-white">О нас</b>
          <div className="relative tracking-[0.03em] leading-[105%] inline-block w-[760px]">
            <p className="m-0">
              "ГЛОБАЛ ЛОГИСТИК" - ведущая компания, основанная в 2007 году,
              специализирующаяся на предоставлении услуг в сфере прочего
              сухопутного пассажирского транспорта, строительстве жилых и
              нежилых зданий, техническом обслуживании и ремонте
              автотранспортных средств, а также оказывающая ряд других услуг.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Ключевой фокус деятельности "ГЛОБАЛ ЛОГИСТИК" - обеспечение
              надежной и эффективной логистики в сфере пассажирского транспорта.
              Компания предлагает широкий спектр транспортных услуг, включающий
              организацию перевозок пассажиров на дальние и ближние расстояния,
              аренду и лизинг пассажирского транспорта, а также услуги такси и
              шаттл-перевозки. "ГЛОБАЛ ЛОГИСТИК" гарантирует своим клиентам
              высокий уровень комфорта, безопасности и пунктуальности во всех
              видах пассажирских перевозок.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Компания также имеет значительный опыт в строительстве жилых и
              нежилых зданий. Ее высококвалифицированные специалисты предлагают
              полный спектр услуг - от разработки проекта и планирования до
              строительства, реконструкции и отделки зданий. Компания активно
              работает как с коммерческими, так и с частными клиентами,
              предоставляя индивидуальные решения и гарантируя высокое качество
              и соблюдение сроков.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Одной из важных составляющих деятельности "ГЛОБАЛ ЛОГИСТИК"
              является техническое обслуживание и ремонт автотранспортных
              средств. Компания обладает современным оборудованием и
              высококвалифицированными специалистами, способными обеспечить
              качественное обслуживание и ремонт автомобилей различных марок и
              моделей. Клиенты могут рассчитывать на профессиональное
              техническое обслуживание, диагностику, ремонт и замену деталей.
            </p>
          </div>
          <div className="relative tracking-[0.03em] leading-[105%] inline-block w-[760px]">
            <p className="m-0">
              <b className="font-montserrat">{`Наш телефон: `}</b>
              <span>+7 (930) 920 48-76</span>
            </p>
            <p className="m-0">
              <b className="font-montserrat">{`Наша почта: `}</b>
              <span>gl.logist@rambler.ru</span>
            </p>
          </div>
        </div>
        <img
          className="self-stretch relative rounded-lg max-h-full w-[584px] object-cover"
          alt=""
          src="/rectangle-8@2x.png"
        />
      </div>
      <footer className="self-stretch bg-white flex flex-col py-0 px-20 items-start justify-center text-left text-base text-blue-gray-600 font-body-xs">
        <div className="self-stretch flex flex-row py-12 px-0 items-start justify-start gap-[48px] border-b-[1px] border-solid border-blue-gray-200">
          <div className="flex-1 flex flex-col items-start justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative w-[220px] h-[67.76px] bg-[url(/public/logo1@3x.png)] bg-cover bg-no-repeat bg-[top]"
              to="/"
            />
          </div>
          <nav className="m-0 flex-1 flex flex-col items-start justify-start text-left text-base text-blue-gray-600 font-body-xs">
            <div className="self-stretch flex flex-row py-3 px-0 items-center justify-start gap-[8px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--codepen4.svg"
              />
              <div className="relative leading-[110%] font-medium">
                Компания
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/about"
              >
                О нас
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/goods"
              >
                Товары
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Услуги
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Контакты
              </Link>
            </div>
          </nav>
        </div>
        <div className="self-stretch flex flex-row pt-12 px-0 pb-6 items-center justify-start">
          <div className="flex-1 relative leading-[140%]">
            @ 2023 globallogisticspro.ru. Все права защищены
          </div>
        </div>
        <div className="self-stretch flex flex-row pt-6 px-0 pb-12 items-start justify-start text-xs">
          <div className="flex-1 relative leading-[140%]">
            {" "}
            "ГЛОБАЛ ЛОГИСТИК" предлагает широкий спектр услуг в области
            сухопутного пассажирского транспорта, строительства и технического
            обслуживания автотранспортных средств. Компания сочетает опыт,
            надежность, высокое качество услуг и индивидуальный подход, чтобы
            удовлетворить потребности своих клиентов и оставаться лидером в
            своей отрасли.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;
