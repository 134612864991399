import { FunctionComponent } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Contact: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-29xl text-white font-montserrat">
      <header className="self-stretch flex flex-row py-6 px-20 items-center justify-between">
        <img
          className="relative w-[220px] h-[67.76px] object-cover"
          alt=""
          src="/logo@2x.png"
        />
        <nav className="m-0 flex flex-row items-start justify-start gap-[16px] text-left text-base text-blue-3 font-body-xs">
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <a
              className="[text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              href="/"
            >
              Главная
            </a>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/about"
            >
              О нас
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/services"
            >
              Услуги
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/goods"
            >
              Товары
            </Link>
          </div>
          <div className="rounded-8xs flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-3">
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <Link
                className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
                to="/contact"
              >
                Контакты
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div className="self-stretch bg-blue-3 flex flex-row p-12 items-start justify-center">
        <div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
          <b className="relative">Контакты</b>
          <div className="relative text-xl text-gray-6 inline-block w-[1824px]">
            <p className="m-0">
              <span>
                <span className="leading-[120%]">
                  Если у вас возникли вопросы, требуется дополнительная
                  информация или вы заинтересованы в наших услугах, не
                  стесняйтесь обращаться к нам. Наша команда экспертов всегда
                  готова оказать вам помощь и предоставить необходимую
                  поддержку.
                </span>
              </span>
            </p>
            <p className="m-0">
              <span>
                <span className="leading-[150%]">&nbsp;</span>
              </span>
            </p>
            <p className="m-0">
              <span>
                <span className="leading-[150%]">
                  Вы можете связаться с нами по следующим контактным данным:
                </span>
              </span>
            </p>
            <p className="m-0">
              <span className="leading-[150%]">
                <span className="font-medium">Телефон: +7 (930) 920 48-76</span>
              </span>
            </p>
            <p className="m-0">
              <span className="leading-[150%]">
                <span className="font-medium">
                  Электронная почта: gl.logist@rambler.ru
                </span>
              </span>
            </p>
            <p className="m-0">
              <span className="leading-[150%]">
                <span className="font-medium">{`Наш офис: Москва, Варшавское ш, д. 1а, помещ. 3к/1п `}</span>
              </span>
            </p>
          </div>
          <iframe
            className="[border:none] self-stretch relative h-[430px] overflow-hidden shrink-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2255.846883035838!2d37.59369507719021!3d55.569864606066425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414aadea44e5726b%3A0xeb1df078f06985c8!2z0JLQsNGA0YjQsNCy0YHQutC-0LUg0YguLCAxMTggMSwg0JzQvtGB0LrQstCwLCAxMTc0MTg!5e0!3m2!1sru!2sru!4v1689419855500!5m2!1sru!2sru"
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-5xl font-body-xs">
            <b className="relative">Наши реквизиты</b>
            <div className="relative text-[inherit] font-inherit">
              <ul className="m-0 pl-[21px]">
                <li className="mb-0">Полное наименование: ООО "Глобал Логистик"</li>
                <li className="mb-0">ИНН/КПП: 7737520205 / 772601001</li>
                <li className="mb-0">ОГРН: 5077746746248</li>
                <li className="mb-0">
                  Р/С: 40702810040000086272 в ПАО «Сбербанк России»
                </li>
                <li className="mb-0">БИК: 044525225</li>
                <li className="mb-0">К/С: 30101810400000000225</li>
                <li>
                  Юр. адрес: 117105, город Москва, Варшавское ш, д. 1а, помещ.
                  3к/1п
                </li>
              </ul>
            </div>
          </div>
          <form
            className="self-stretch flex flex-col items-start justify-start gap-[24px]"
            action="https://formsubmit.co/gl.logist@rambler.ru"
            method="post"
          >
            <b className="relative text-5xl font-body-xs text-white text-left">
              Связаться с нами
            </b>
            <Form.Group className="w-[250px] [border:none] bg-[transparent]">
              <Form.Control type="text" name="name" placeholder="Ваше имя" />
            </Form.Group>
            <Form.Group className="w-[250px] [border:none] bg-[transparent]">
              <Form.Control type="text" name="email" placeholder="Ваш email" />
            </Form.Group>
            <Form.Group className="w-[395px]">
              <Form.Control
                as="textarea"
                name="text"
                placeholder="Ваше сообщение"
              />
            </Form.Group>
            <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-[140px] h-10">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-steelblue" />
              <div className="absolute top-[calc(50%_-_10px)] left-[18.57%] text-base font-medium font-montserrat text-gray-5 text-center">
                Отправить
              </div>
            </button>
          </form>
        </div>
      </div>
      <footer className="self-stretch bg-white flex flex-col py-0 px-20 items-start justify-center text-left text-base text-blue-gray-600 font-body-xs">
        <div className="self-stretch flex flex-row py-12 px-0 items-start justify-start gap-[48px] border-b-[1px] border-solid border-blue-gray-200">
          <div className="flex-1 flex flex-col items-start justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative w-[220px] h-[67.76px] bg-[url(/public/logo1@3x.png)] bg-cover bg-no-repeat bg-[top]"
              to="/"
            />
          </div>
          <nav className="m-0 flex-1 flex flex-col items-start justify-start text-left text-base text-blue-gray-600 font-body-xs">
            <div className="self-stretch flex flex-row py-3 px-0 items-center justify-start gap-[8px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--codepen4.svg"
              />
              <div className="relative leading-[110%] font-medium">
                Компания
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/about"
              >
                О нас
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/goods"
              >
                Товары
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Услуги
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Контакты
              </Link>
            </div>
          </nav>
        </div>
        <div className="self-stretch flex flex-row pt-12 px-0 pb-6 items-center justify-start">
          <div className="flex-1 relative leading-[140%]">
            @ 2023 globallogisticspro.ru. Все права защищены
          </div>
        </div>
        <div className="self-stretch flex flex-row pt-6 px-0 pb-12 items-start justify-start text-xs">
          <div className="flex-1 relative leading-[140%]">
            {" "}
            "ГЛОБАЛ ЛОГИСТИК" предлагает широкий спектр услуг в области
            сухопутного пассажирского транспорта, строительства и технического
            обслуживания автотранспортных средств. Компания сочетает опыт,
            надежность, высокое качество услуг и индивидуальный подход, чтобы
            удовлетворить потребности своих клиентов и оставаться лидером в
            своей отрасли.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
