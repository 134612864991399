import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

const Services: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-5xl text-gray-6 font-montserrat">
      <header className="self-stretch flex flex-row py-6 px-20 items-center justify-between">
        <img
          className="relative w-[220px] h-[67.76px] object-cover"
          alt=""
          src="/logo@2x.png"
        />
        <nav className="m-0 flex flex-row items-start justify-start gap-[16px] text-left text-base text-blue-3 font-body-xs">
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <a
              className="[text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              href="/"
            >
              Главная
            </a>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/about"
            >
              О нас
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/services"
            >
              Услуги
            </Link>
          </div>
          <div className="self-stretch flex flex-row py-3 px-2 items-center justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
              to="/goods"
            >
              Товары
            </Link>
          </div>
          <div className="rounded-8xs flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-3">
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <Link
                className="cursor-pointer [text-decoration:none] relative tracking-[0.5px] leading-[24px] font-medium text-[inherit]"
                to="/contact"
              >
                Контакты
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div className="self-stretch bg-blue-3 flex flex-row p-12 items-start justify-center">
        <div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
          <b className="relative text-29xl text-white">Наши услуги</b>
          <div className="relative inline-block w-[1303px]">
            Компания предлагает широкий спектр транспортных услуг, включающий
            организацию перевозок пассажиров на дальние и ближние расстояния,
            аренду и лизинг пассажирского транспорта, а также услуги такси и
            шаттл-перевозки.
          </div>
          <div className="self-stretch flex flex-row py-6 px-0 items-start justify-start gap-[48px] text-center">
            <div className="h-[431px] flex flex-col p-6 box-border items-center justify-center bg-[url(/public/smallservice@3x.png)] bg-cover bg-no-repeat bg-[top]">
              <div className="relative leading-[80%] font-medium inline-block w-[302px]">
                Логистические услуги
              </div>
            </div>
            <div className="flex-1 rounded-tl-17xl rounded-tr-none rounded-br-none rounded-bl-17xl h-[431px] overflow-hidden flex flex-col pt-6 px-6 pb-0 box-border items-start justify-start gap-[16px] bg-[url(/public/bigservice@3x.png)] bg-cover bg-no-repeat bg-[top] text-left text-13xl text-white">
              <div className="relative leading-[80%] font-medium">
                Консалтинговые услуги
              </div>
              <div className="relative text-xl leading-[100%] text-gray-5 inline-block w-[843px]">
                <p className="m-0">
                  Компания предоставляет консультационную поддержку и экспертное
                  мнение в различных сферах, связанных с транспортом,
                  строительством и техническим обслуживанием автотранспорта.
                  Квалифицированные специалисты "ГЛОБАЛ ЛОГИСТИК" помогут
                  клиентам разработать оптимальные решения, справиться с
                  проблемами и повысить эффективность своих операций.
                </p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row py-6 px-0 items-start justify-start gap-[48px] text-13xl text-white">
            <div className="flex-1 rounded-tl-none rounded-tr-17xl rounded-br-17xl rounded-bl-none h-[431px] overflow-hidden flex flex-col pt-6 px-6 pb-0 box-border items-start justify-start gap-[16px] bg-[url(/public/bigservice1@3x.png)] bg-cover bg-no-repeat bg-[top]">
              <div className="relative leading-[80%] font-medium">
                Услуги по разработке проектов
              </div>
              <div className="relative text-xl leading-[100%] text-gray-5 inline-block w-[851px]">
                Компания предоставляет услуги по разработке проектов в сфере
                строительства и транспорта. Это включает создание
                дизайн-проектов, проектирование инженерных систем, составление
                смет и разработку строительных планов. "ГЛОБАЛ ЛОГИСТИК"
                помогает клиентам осуществить свои проекты с высоким качеством и
                эффективностью.
              </div>
            </div>
            <div className="h-[431px] flex flex-col p-6 box-border items-center justify-center bg-[url(/public/smallservice1@3x.png)] bg-cover bg-no-repeat bg-[top] text-center text-5xl text-gray-6">
              <div className="relative leading-[80%] font-medium inline-block w-[302px]">
                <p className="m-0">Строительство</p>
                <p className="m-0">домов</p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row pt-6 px-0 pb-12 items-start justify-start gap-[48px] text-center">
            <div className="h-[431px] flex flex-col p-6 box-border items-center justify-center bg-[url(/public/smallservice2@3x.png)] bg-cover bg-no-repeat bg-[top]">
              <div className="relative leading-[80%] font-medium inline-block w-[302px]">
                Аренда и лизинг
              </div>
            </div>
            <div className="flex-1 rounded-tl-17xl rounded-tr-none rounded-br-none rounded-bl-17xl h-[431px] overflow-hidden flex flex-col pt-6 px-6 pb-0 box-border items-start justify-start gap-[16px] bg-[url(/public/bigservice2@3x.png)] bg-cover bg-no-repeat bg-[top] text-left text-13xl text-white">
              <div className="relative leading-[80%] font-medium">
                Техническое обслуживание и ремонт
              </div>
              <div className="relative text-xl leading-[100%] text-gray-5 inline-block w-[848px]">
                Компания предоставляет услуги по техническому обслуживанию и
                ремонту автотранспортных средств, включая регулярное техническое
                обслуживание, диагностику, ремонт и замену деталей. "ГЛОБАЛ
                ЛОГИСТИК" гарантирует профессиональное обслуживание и быстрое
                восстановление транспортных средств для безопасной и
                бесперебойной работы клиентов.
              </div>
            </div>
          </div>
          <Button
            buttonHref="/goods"
            buttonPadding="16px"
            buttonText="Наши товары"
            buttonTextFontSize="20px"
            buttonBackgroundColor="#0096ce"
            buttonBorder="unset"
          />
        </div>
      </div>
      <footer className="self-stretch bg-white flex flex-col py-0 px-20 items-start justify-center text-left text-base text-blue-gray-600 font-body-xs">
        <div className="self-stretch flex flex-row py-12 px-0 items-start justify-start gap-[48px] border-b-[1px] border-solid border-blue-gray-200">
          <div className="flex-1 flex flex-col items-start justify-start">
            <Link
              className="cursor-pointer [text-decoration:none] relative w-[220px] h-[67.76px] bg-[url(/public/logo1@3x.png)] bg-cover bg-no-repeat bg-[top]"
              to="/"
            />
          </div>
          <nav className="m-0 flex-1 flex flex-col items-start justify-start text-left text-base text-blue-gray-600 font-body-xs">
            <div className="self-stretch flex flex-row py-3 px-0 items-center justify-start gap-[8px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--codepen3.svg"
              />
              <div className="relative leading-[110%] font-medium">
                Компания
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/about"
              >
                О нас
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/goods"
              >
                Товары
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Услуги
              </Link>
              <Link
                className="cursor-pointer [text-decoration:none] relative leading-[140%] text-[inherit]"
                to="/services"
              >
                Контакты
              </Link>
            </div>
          </nav>
        </div>
        <div className="self-stretch flex flex-row pt-12 px-0 pb-6 items-center justify-start">
          <div className="flex-1 relative leading-[140%]">
            @ 2023 globallogisticspro.ru. Все права защищены
          </div>
        </div>
        <div className="self-stretch flex flex-row pt-6 px-0 pb-12 items-start justify-start text-xs">
          <div className="flex-1 relative leading-[140%]">
            {" "}
            "ГЛОБАЛ ЛОГИСТИК" предлагает широкий спектр услуг в области
            сухопутного пассажирского транспорта, строительства и технического
            обслуживания автотранспортных средств. Компания сочетает опыт,
            надежность, высокое качество услуг и индивидуальный подход, чтобы
            удовлетворить потребности своих клиентов и оставаться лидером в
            своей отрасли.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Services;
